import React, { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next'
import config from 'components/config';
import { exchange } from 'components/lib/auth';
import queryparam from 'components/lib/queryparam';
import { setSession, REDIRECT_AFTER_LOGIN, removeCoockie, getCoockie } from 'components/lib/session';
import Layout from 'components/layout/simple';
import { useSession } from 'components/context/session-context';

const { paths } = config;

const AuthCallback = () => {
  const { loadSession } = useSession();
  const { t } = useTranslation()

  const onAuthSuccess = useCallback(({ data: { idToken, accessToken, refreshToken} = {} }) => {
    setSession({
      idToken,
      accessToken,
      refreshToken,
      timestamp: new Date().getTime()
    });

    loadSession();

    // const afterLoginURL = getCoockie(REDIRECT_AFTER_LOGIN);

    // if (afterLoginURL !== null) {
    //   removeCoockie(REDIRECT_AFTER_LOGIN);
    //   navigate(afterLoginURL);
    // } else {
    //   navigate(paths.logged);
    // }

    navigate(paths.logged)
  }, []);

  const onAuthError = useCallback((e) => {
    navigate(paths.login);
    console.error('* authentication error', e);
  }, []);

  useEffect(() => {
    const code = queryparam('code');

    if (!code) {
      return this.onAuthError(new Error(t('common.errorMessage.noAccessCode')))
    }

    exchange(code)
      .then(onAuthSuccess)
      .catch(onAuthError);
  }, [onAuthSuccess, onAuthError]);

  return (
    <Layout>
      <Helmet title={t('pages.helmetTitle.authenticating')} />
      <section className="section authentication">
        <div className="inner content">
          <div className="loader mt-5 pt-5" />
        </div>
      </section>
    </Layout>
  )
}

export default AuthCallback;